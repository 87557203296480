import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/pc-live247-react.scss";
import "./index.scss";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { applyMiddleware, legacy_createStore as createStore } from "redux";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import PrivateRoute from "./privateRoute";
import { Provider } from "react-redux";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import ReduxThunk from "redux-thunk";
import reducers from "./reducers/index";
import { CircularProgress } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={createStore(reducers, {}, applyMiddleware(ReduxThunk))}>
    <BrowserRouter>
      <Suspense fallback={<CircularProgress color="inherit" />}>
        <Switch>
          <PrivateRoute component={AdminLayout} path="/admin" />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </Provider>
);
