import React, {Suspense, lazy, useEffect, useState} from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "components/Sidebar/Sidebar.js";
import { saveData } from "../actions/HomeAction";
import { useDispatch } from "react-redux";

const Users = lazy(() => import("../views/users/Users"));
const Catagories = lazy(() => import("../views/categories/Catagories"));
const Cards = lazy(() => import("../views/cards/CardTabs"));
const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));
const Version = lazy(() => import("../views/version/Version"));
const Policy = lazy(() => import("../views/policy/Policy"));
const Terms = lazy(() => import("../views/policy/TermsAndCondition"));
const Shipments = lazy(() => import("../views/shipments/Shipments"));
const Inventorytag = lazy(() => import("../views/inventory-tag/InventoryTag"));
const CorporateCards = lazy(() =>
  import("../views/corporate-cards/CorporateCards")
);
const Payouts = lazy(() => import("../views/payouts/Payouts"));
const Address = lazy(() => import("../views/address/Address"));

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-app text-blue",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-blue",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Category",
    icon: "ni ni-books text-blue",
    component: Catagories,
    layout: "/admin",
  },
  {
    path: "/inventory",
    name: "Inventory tag",
    icon: "ni ni-tag text-blue",
    component: Inventorytag,
    layout: "/admin",
  },
  {
    path: "/corporate-cards",
    name: "Corporate Cards",
    icon: "ni ni-archive-2 text-blue",
    component: CorporateCards,
    layout: "/admin",
  },
  {
    path: "/cards",
    name: "Cards",
    icon: "ni ni-single-copy-04 text-blue",
    component: Cards,
    layout: "/admin",
  },
  {
    path: "/address",
    name: "Address",
    icon: "ni ni-pin-3 text-blue",
    component: Address,
    layout: "/admin",
  },
  {
    path: "/version",
    name: "App Versions",
    icon: "ni ni-atom text-blue",
    component: Version,
    layout: "/admin",
  },
  {
    path: "/policy",
    name: "Privacy Policy",
    icon: "ni ni-key-25 text-info",
    component: Policy,
    layout: "/admin",
  },
  {
    path: "/terms",
    name: "Terms and Condition",
    icon: "ni ni-key-25 text-info",
    component: Terms,
    layout: "/admin",
  },
  {
    path: "/shipments",
    name: "Shipments",
    icon: "ni ni-box-2 text-blue",
    component: Shipments,
    layout: "/admin",
  },
  {
    path: "/payouts",
    name: "Payouts",
    icon: "ni ni-paper-diploma text-blue",
    component: Payouts,
    layout: "/admin",
  },
];

var sellerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-app text-blue",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/cards",
    name: "Cards",
    icon: "ni ni-single-copy-04 text-blue",
    component: Cards,
    layout: "/admin",
  },
  {
    path: "/address",
    name: "Address",
    icon: "ni ni-pin-3 text-blue",
    component: Address,
    layout: "/admin",
  },
  {
    path: "/shipments",
    name: "Shipments",
    icon: "ni ni-box-2 text-blue",
    component: Shipments,
    layout: "/admin",
  },
  {
    path: "/policy",
    name: "Policy",
    icon: "ni ni-key-25 text-info",
    component: Policy,
    layout: "/admin",
  },
  {
    path: "/terms",
    name: "Terms and Condition",
    icon: "ni ni-key-25 text-info",
    component: Terms,
    layout: "/admin",
  },
];

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [routeState, currentRoutes] = useState([]);

  useEffect(() => {
    const sellerState = JSON.parse(localStorage.getItem("userDetail"));

    if (sellerState) {
      if (sellerState.is_seller && !sellerState.is_superuser) {
        currentRoutes(sellerRoutes);
      } else {
        currentRoutes(routes);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(saveData("somedata"));
  }, [dispatch]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routeState.filter((f) => f.path !== "/login")}
        logo={{
          innerLink: "",
          imgSrc: require("../assets/img/brand/pclive247.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <div style={{ padding: "100px 20px 0 20px" }}>
          <Suspense fallback={<CircularProgress color="inherit" />}>
            <Switch>
              {getRoutes(routeState)}
              <Redirect from="*" to="/admin/dashboard" />
            </Switch>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Admin;
