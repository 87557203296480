import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, path }) => {
  const accessToken = localStorage.getItem("accessToken");

  return (
    <Route
      path={path}
      render={(props) =>
        accessToken == null ? (
          <Redirect to="/auth/login" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
