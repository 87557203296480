import { lazy } from "react";

const Login = lazy(() => import("views/Login.js"));
const FAQ = lazy(() => import("views/terms-mobile/FAQ"));
const OTPPage = lazy(() => import("views/otp/OTP"));
const Policy = lazy(() => import("views/terms-mobile/PrivacyPolicy"));
const Register = lazy(() => import("views/register/Register"));
const TermsAndCondition = lazy(() =>
  import("views/terms-mobile/TermsAndCondition")
);
const Varify = lazy(() => import("views/stripe/Varify.js"));

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/varify",
    name: "Stripe",
    icon: "ni ni-key-25 text-info",
    component: Varify,
    layout: "/auth",
  },
  {
    path: "/terms",
    name: "Terms and Condition",
    icon: "ni ni-key-25 text-info",
    component: TermsAndCondition,
    layout: "/auth",
  },
  {
    path: "/policy",
    name: "Privacy Policy",
    icon: "ni ni-key-25 text-info",
    component: Policy,
    layout: "/auth",
  },
  {
    path: "/otp",
    name: "OTP",
    icon: "ni ni-key-25 text-info",
    component: OTPPage,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "ni ni-key-25 text-info",
    component: FAQ,
    layout: "/auth",
  },
];
export default routes;
