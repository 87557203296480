export const getCurrentDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getToken(),
  };
};