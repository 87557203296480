import {
  GET_ADDRESS_FAIL,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_CARD_FAIL,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  GET_REQUESTHISTORY_FAIL,
  GET_REQUESTHISTORY_REQUEST,
  GET_REQUESTHISTORY_SUCCESS,
  GET_SELLER_ADDRESS_FAIL,
  GET_SELLER_ADDRESS_REQUEST,
  GET_SELLER_ADDRESS_SUCCESS,
  GET_VERSION_FAIL,
  GET_VERSION_REQUEST,
  GET_VERSION_SUCCESS,
  HOME_PAGE,
  POST_ACTIONREQUEST_FAIL,
  POST_ACTIONREQUEST_REQUEST,
  POST_ACTIONREQUEST_SUCCESS,
  POST_ADDRESS_FAIL,
  POST_ADDRESS_REQUEST,
  POST_ADDRESS_SUCCESS,
  POST_LOGIN_FAIL,
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  CLEAR_CARD_DETAILS,
} from "../constants/Home";

const INITIAL_STATE = {
  data: null,
  error: false,
  isLoading: false,
  loginData: {},
  getAddress: {},
  sellerAddress: {},
  allVersion: [],
  totalPage: 1,
  cardDetails: {},
  requestHistory: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HOME_PAGE:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loginData: action.payload,
      };

    case GET_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ADDRESS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_SELLER_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sellerAddress: action.payload.data,
      };

    case GET_SELLER_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SELLER_ADDRESS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getAddress: action.payload.data,
      };

    case GET_VERSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_VERSION_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_VERSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allVersion: action.payload.data,
        totalPage: action.payload.total_pages,
      };

    case POST_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_ADDRESS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_REQUESTHISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_REQUESTHISTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_REQUESTHISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestHistory: action.payload.data,
      };
    case GET_CARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case GET_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardDetails: action.payload.data,
      };

    case CLEAR_CARD_DETAILS:
      return {
        ...state,
        cardDetails: [],
      };

    case POST_ACTIONREQUEST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_ACTIONREQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case POST_ACTIONREQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        //cardDetails: action.payload.data
      };

    default:
      return state;
  }
};
